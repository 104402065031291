import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

type historyParams = {
  receiptSlipPrintHistory: {
    to: string | null,
    issuedDate: string,
    note: string | null,
    amount: number,
    receiptId?: number | null,
    issuerId: number,
  }
}

export default {
  createReceiptSlipPrintHistory: async (params: historyParams) => {
    const data = serialize(snakecaseKeys(params))

    return axiosIns.post('/v2/audit/receipt_slip_print_histories', data)
  },
}
