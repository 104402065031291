import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getClub() {
    return axiosIns.get('/waiter/clubs')
  },
  updateClub({
    name = null,
    address = null,
    telephoneNumber = null,
    openAt = null,
    closeAt = null,
    referralAlias = null,
    courseAlias = null,
    nominationAlias = null,
    itemAlias = null,
    printIpAddress = null,
    receiptPreviewBackgroundImage = null,
    receiptQrCodeBackgroundImage = null,
    defaultTaxCharge = null,
    invoiceNumber = null,
    tableMoveTaxRecalc = null,
  }) {
    const formData = serialize(snakecaseKeys({
      club: {
        name,
        address,
        telephoneNumber,
        openAt,
        closeAt,
        referralAlias,
        courseAlias,
        itemAlias,
        nominationAlias,
        printIpAddress,
        defaultTaxCharge,
        invoiceNumber,
        tableMoveTaxRecalc,
      },
    }))
    // NOTE: HACK: 直接いれないと送信されない
    // NOTE: null送るとerrorになる
    if (receiptPreviewBackgroundImage) formData.append('club[receipt_preview_background_image]', receiptPreviewBackgroundImage)
    if (receiptQrCodeBackgroundImage) formData.append('club[receipt_qr_code_background_image]', receiptQrCodeBackgroundImage)

    return axiosIns.put('/waiter/clubs', formData)
  },
}
